<template>
  <div :class="[layer == 0 ? 'cn-menu-wrap' : 'cn-tree-menu']">
    <div
      v-if="title || (item && item.hasOwnProperty('is_new'))"
      @click="handleClick(item)"
      :class="{'menu-item': true, 'unfold': unfold,
        'single': layer > 1 && item.children.length == 0,
        'active': (!with_model && (active_hash_key == item.hash_key)) || 
          (with_model && (!item.is_root && active_hash_key == item.hash_key))}"
      :style="computedStyle">
      <i v-if="children.length > 0" class="el-icon-arrow-right arrow-right"></i>
        <span v-if="children.length == 0" style="display: inline-block;width: 22px"></span>
      <input v-if="item.is_new" type="text" class="menu-input" placeholder="请输入标题" v-model="new_title" @click.stop="() => {}" @keyup="handleInputChange">
      <span v-else class="title" :title="title">{{title}}</span>
      <span v-if="type === 2 && !item.is_new" class="icon-opt icon-plus" @click.stop="(e) => handleClickPlus(e, item)" title="添加兄弟或子节点">+</span>
      <span v-if="type === 2 && !item.is_new" class="icon-opt icon-edit" @click.stop="handleClickEdit(item)" title="修改">
        <svg class="iconfont-edit" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12179" width="16" height="16"><path d="M550.4 292.608l180.992 181.034667L308.992 896H128v-181.034667l422.4-422.4z m60.330667-60.330667l90.496-90.538666a42.666667 42.666667 0 0 1 60.330666 0l120.704 120.704a42.666667 42.666667 0 0 1 0 60.330666l-90.538666 90.496-180.992-180.992z" p-id="12180"></path></svg>
      </span>
      <span v-if="type === 2 && !item.is_new && pos.substr(-1) != 0" class="icon-opt icon-moveup" title="向前移动" @click.stop="handleClickMove(item, 1)">↑</span>
      <span v-if="type === 2 && !item.is_new && siblings_num > 1 && pos.substr(-1) < siblings_num - 1" title="向后移动" @click.stop="handleClickMove(item, 0)" class="icon-opt icon-movedown">↓</span>
      <span v-if="type === 2" class="icon-opt icon-x" @click.stop="handleClickDel(item)" title="删除">×</span>
    </div>
    <tree-menu
      v-for="(node, index) in children"
      v-show="layer < 1 || unfold"
      :key="node.id"
      :pos="(pos ? (pos + '-') : '') + index"
      :siblings_num="children.length"
      :with_model="with_model"
      :active_hash_key="active_hash_key"
      :active_model="active_model"
      :title="node.title"
      :item="node"
      :children="node.children"
      :clickMenu="clickMenu"
      :clickDelete="clickDelete"
      :clickEdit="clickEdit"
      :clickPlus="clickPlus"
      :clickMove="clickMove"
      :inputChange="inputChange"
      :type="type"
      :layer="layer + 1">
    </tree-menu>
  </div>
</template>
<script>
export default {
  name: 'treeMenu',
  props: ['with_model', 'active_model', 'title', 'active_hash_key', 'children', 'item', 'layer', 'siblings_num', 'clickMenu', 'clickMove', 'clickDelete', 'clickEdit', 'clickPlus', 'inputChange', 'pos', 'type'],
  data() {
    return {
      unfold: this.item?this.item.unfold:false,
      new_title: ''
    }
  },
  computed: {
    computedStyle() {
      let last = this.children.length == 0
      return { paddingLeft: `${this.layer * 16 + (last ? 4 : 0)}px` }
    },
  },
  mounted() {
    this.defaultUnfold()
  },
  methods: {
    defaultUnfold() {
      let item = this.item
      if (!item) return
      // 若菜单匹配当前active，则将其父级展开
      // 若当前菜单是包含active菜单的某一级祖辈则此时它已是展开状态，直接再将它的父辈展开
      if (item.hash_key == this.active_hash_key || this.unfold) {
        this.$parent.unfold = true
      }
    },
    handleClick(menu) {
      this.unfold = !this.unfold
      // 如果是移动端套了一层模块菜单，点击模块时只需展开子级，
      // 不必传递clickMenu事件，否则前一个active的菜单会失去active
      if (menu.is_root) return;
        if(menu.children && menu.children.length == 0){
            this.clickMenu(menu)
        }
    },
    handleClickPlus(e, menu) {
      this.clickPlus(e, this.pos, menu)
    },
    handleClickEdit(menu) {
      this.clickEdit(menu, this.pos)
    },
    handleClickDel(item) {
      this.clickDelete(item, 0)
    },
    handleClickMove(item, direction) {
      this.clickMove(item, this.pos, direction)
    },
    handleInputChange() {
      this.inputChange(this.new_title)
    }
  }
}
</script>
<style lang="less" scoped>
.menu-item {
  display: flex;
  align-items: center;
  min-width: 140px;
  height: 40px;
  line-height: 16px;
  font-size: 16px;
  color: #262626;
  user-select: none;
  position: relative;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    &:hover{
        background: #eceff0;
    }
  > * {
    cursor: pointer;
  }
  .title {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-opt {
    position: relative;
    color: #6976FF;
    opacity: 0;
    margin-left: 6px;
    transition: opacity .3s;
  }
  .icon-plus, .icon-x, .icon-edit {
    margin-top: -3px;
    font-size: 26px;
  }
  .icon-x, .icon-edit {
    margin-top: -1px;
  }
  .icon-moveup, .icon-movedown {
    margin-top: 2px;
    margin-left: 10px;
  }
  .icon-movedown {
    margin-right: 4px;
  }
  .iconfont-edit path {
    fill: #6976FF;
  }
  &:hover {
    .icon-opt {
      opacity: 1;
    }
  }
  .menu-input {
    border: 0;
    border-bottom: 1px solid rgba(105, 118, 255, 1);
    font-size: 14px;
    line-height: 20px;
    background: transparent;
    outline: none;
    cursor: auto;
  }
  .arrow-right {
    margin-right: 8px;
    transition: transform .2s cubic-bezier(.21, .70, .13, .86);
  }
  &.unfold {
    .arrow-right {
      transform: rotateZ(90deg);
    }
  }
  &.active {
    color: #6976FF;
      background: #eceff0;
      &::after{
          content: "";
          width: 5px;
          height: 41px;
          background: #6976FF;
          position: absolute;
          left: 0;
          top: 0;
      }
  }
  .drop-bar {
    position: absolute;
    left: 0;
    width: 26px;
    height: 40px;
    cursor: move;
  }
}

</style>
